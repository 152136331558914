<template>
  <div v-if="lesson" class="container">
    <PageTitle marginBottom>
      {{ `Edit ${pageTitle} Video Lessons` }}
    </PageTitle>
    <LessonForm :defaultLesson="lesson" submitText="Update" @submit="submit" />
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import LessonForm from "@/views/toefl/lessons/LessonForm.vue";
import lessonsApi from "@/apis/lessons";

export default {
  metaInfo() {
    return {
      title: `Edit ${this.pageTitle} - ` + this.CompanyName
    };
  },
  components: {
    PageTitle,
    LessonForm
  },
  computed: {
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    pageTitle() {
      return this.lesson?.title[this.lang] || "";
    }
  },
  data() {
    return {
      lesson: null
    };
  },
  async created() {
    this.fetchLesson();
  },
  methods: {
    async fetchLesson() {
      const { lesson } = await lessonsApi.getLesson(this.$route.query.v);
      this.lesson = lesson;
    },
    async submit(lesson) {
      try {
        await lessonsApi.updateLesson(this.$route.query.v, lesson);
        this.$message.success(this.$t("message.update_success"));
        this.$router.push({ name: "Lessons" });
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 40px;
}
</style>
